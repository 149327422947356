import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import MenuIconNew from "../assets/images/menu-button.png";

// import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.webp";
import { useSelector } from "react-redux";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import ModalWrapper from "./ModalWrapper";

import { getNotifications } from "../redux/app/actions";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleShow1 = () => setShow1(!show1);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDateTime = currentDateTime.toLocaleString("en-US", options);

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (
      show1 ||
      showAuthModals.login ||
      showAuthModals.mobileNumber ||
      showAuthModals.verifyNumber ||
      showAuthModals.register ||
      showAuthModals.forgotpassword ||
      showAuthModals.resetpassword
    ) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [
    show1,
    showAuthModals.login,
    showAuthModals.mobileNumber,
    showAuthModals.verifyNumber,
    showAuthModals.register,
    showAuthModals.forgotpassword,
    showAuthModals.resetpassword,
  ]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header header_bfrlgn">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIconNew} alt="User Menu Icon" width={25} />
                </Button>
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                  <div className="dateTime">
                    <span>{formattedDateTime}</span>
                    {/* <span class="country-standred-time">(+05:30)</span> */}
                  </div>
                </div>

                <GamesSearchModal />

                <div className="headerRight ms-auto">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                    }}
                    className="login_btn me-2"
                  >
                    Log In
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    }}
                    className="signup_btn"
                  >
                    Sign Up
                  </Button>

                  {/* <div className="searchbar">
                  <AiOutlineSearch />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="btmHead">
          <ul>
            <li>
              <a href="/sports-exchange">Exchange</a>
            </li>
            <li>
              <a href="/betby">Sportsbook</a>
            </li>
            <li>
              <a href="/sports-exchange">Cricket</a>
            </li>
            <li>
              <a href="/sports-exchange">Football</a>
            </li>
            <li>
              <a href="/sports-exchange">Tennis</a>
            </li>
            <li>
              <a href="/beforeprovider">Live Casino</a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                Slots Games
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                Jilli Games
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                Kingmaker Games
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                AeSexy Games
              </a>
            </li>
            <li>
              <a href="/atlas-lobby">Sportsbook2</a>
            </li>
          </ul>
        </div>
      </header>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setDefaultModal}
        />
      )}
      {show1 && <MobLeftbar className="d-block d-md-none" />}
    </>
  );
};

export default Header;
